import { daysAgo, formatISODate } from "../../../../utils/datetime";
import {
  AnalyticsDimension,
  Maybe,
  MetricName,
  useAnalyticsBenchmarksQuery,
  useAnalyticsDataQuery,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";

type QuickStat = {
  value: Maybe<number>;
  loading: boolean;
  benchmarkRange: Maybe<[number, number]>;
};

/**
 * Queries for current user's stats on given metric in the last 30 days
 * and return relevant benchmark data (where available)
 */
const useQuickStat = (metric: MetricName): QuickStat => {
  const currentUser = useCurrentUser();

  const today = new Date();
  const thirtyDaysAgo = daysAgo(30);

  const { data, loading } = useAnalyticsDataQuery({
    variables: {
      metric,
      primaryDimension: AnalyticsDimension.Interviewer,
      secondaryDimension: AnalyticsDimension.None,
      departments: [],
      positions: [],
      stages: [],
      locations: [],
      interviewers: [currentUser.email || ""],
      dateRangeStart: `${formatISODate(thirtyDaysAgo)}`,
      dateRangeEnd: `${formatISODate(today)}`,
      organizationId: currentUser.organization.id,
    },
  });

  const { data: benchmarkData } = useAnalyticsBenchmarksQuery();
  const benchmark = benchmarkData?.benchmarks?.data?.find(
    (b) => b.metric === metric
  );
  const hasBenchmark =
    benchmark?.lowerBound !== undefined && benchmark.upperBound !== undefined;

  return {
    value: data?.analyticsData?.data[0]?.value ?? null,
    loading,
    benchmarkRange: hasBenchmark
      ? [benchmark.lowerBound, benchmark.upperBound]
      : null,
  };
};

export default useQuickStat;
